<template>
  <Page page-title="Tasks" customClass="is-tasks mt-6" :hasRightBar="true">
    <Columns>
      <Column>
        <todo-tasks />
      </Column>
    </Columns>

    <template #rightbar>
      <Columns>
        <Column customClass="mt-6 pt-6">
            <div v-if="selected">
              <widget-tracking-time  />
            </div>
        </Column>
      </Columns>
    </template>
  </Page>
</template>

<script>
import Page from '@/components/layout/Page.vue'
import Columns from '@/components/ds/Columns.vue'
import Column from '@/components/ds/Column.vue'
import useTimeTracking from '@/use/useTimeTracking'
import { defineAsyncComponent, reactive, toRefs } from 'vue'

export default {
  components: {
    Page,
    Columns,
    Column,
    TodoTasks: defineAsyncComponent(() => import('@/components/issues/IssuesByUID')),
    WidgetTrackingTime: defineAsyncComponent(() => import('@/components/widgets/timeTracking')),
  },

  setup () {
    const state = reactive({
      count: 0,
    })

    const { selected } = useTimeTracking();

    console.log(selected.value)

    return {
      ...toRefs(state),
      selected
    }
  }
}
</script>

<style lang="scss" scoped>

</style>